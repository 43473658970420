@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Vazir;
  src: url("./assets/fonts/Vazir.woff2");
  font-weight: normal;
}

* {
  font-family: Vazir;
}

body {
  background: url("./assets/images/background.jpg");
}

.bg-overlay {
  background: #eeea;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.text-input {
  @apply bg-gray-100 border border-gray-200 rounded px-4 py-2 focus:outline-none focus:border-yellow-500;
}

.outline-button {
  @apply text-xs text-yellow-500 px-2 py-1 border border-yellow-500 rounded bg-yellow-500/5 hover:bg-yellow-500/20 transition;
}

.bg-blurry {
  @apply backdrop-blur-lg bg-white/60 shadow;
}

.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
